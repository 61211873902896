<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<div class="row">
			<div
				v-for="widget in widgetData"
				:key="widget.text"
				class="col-xl-4 col-sm-4 col-md-4 col-12"
			>
				<div class="widget-simple text-center card">
					<div class="card-body">
						<h3 class="text-success counter mt-0">{{ widget.number }}</h3>
						<p class="text-muted mb-0">{{ widget.text }}</p>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-lg-6">
				<b-card>
					<h4 class="header-title mb-3">Location vs Money Earned</h4>
					<b-input placeholder="Search here..." v-model="search" />
					<br />
					<b-table
						thead-class="red-t-head"
						:filter="search"
						outlined
						:per-page="perPage"
						:current-page="currentPage"
						hover
						:fields="tableFields"
						:items="tableItems"
					></b-table>
					<b-pagination
						:total-rows="tableItems && tableItems.length"
						:per-page="perPage"
						v-model="currentPage"
					></b-pagination>
				</b-card>
			</div>
			<!-- end col -->

			<div class="col-lg-6">
				<b-card>
					<h4 class="header-title mb-3">Game Report Table</h4>
					<b-input placeholder="Search here..." v-model="searchGame" />
					<br />
					<b-table
						thead-class="red-t-head"
						:filter="searchGame"
						outlined
						:per-page="perPageGame"
						:current-page="currentPageGame"
						hover
						:fields="tableFieldsGame"
						:items="gameTableItems"
					></b-table>
					<b-pagination
						:total-rows="gameTableItems && gameTableItems.length"
						:per-page="perPageGame"
						v-model="currentPageGame"
					></b-pagination>
				</b-card>
			</div>
			<!-- end col -->
		</div>
	</Layout>
</template>

<script>
	import Layout from "../../../layouts/vertical";
	import PageHeader from "@/components/Page-header";
	import { mapActions, mapGetters, mapState } from "vuex";
	import Table from "@/components/tables/BasicTable";

	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	/**
	 * Starter component
	 */
	export default {
		components: {
			Layout,
			PageHeader,
			Table,
		},
		page: {
			title: "Overall Report",
		},

		async beforeMount() {
			await this.getBranches();
			await this.getGames();
			await this.loadGameTable();
			await this.loadLocationTable();
			await this.loadWidgetData();
			// get data on interval from api
			this.pollInterval = setInterval(async () => {
				await this.loadGameTable();
				await this.loadLocationTable();
				await this.loadWidgetData();
			}, 2000);
		},
		destroyed() {
			clearInterval(this.pollInterval);
		},

		data() {
			return {
				pollInterval: null,
				title: "Overall Report",
				items: [{ text: "Reports" }, { text: "Overall Report", active: true }],
				widgetData: {
					totalIn: {
						number: "$0",
						text: "Total Money In",
						chartColor: "#f672a7",
					},
					totalOut: {
						number: "$0",
						text: "Total Money Out",
						chartColor: "#6c757d",
					},
					totalNetProfit: {
						number: "$0",
						text: "Total Net Profit",
						chartColor: "#f672a7",
					},
				},
				//location vs Money earned vars
				currentPage: 1,
				perPage: 5,
				search: "",
				tableFields: [
					{
						label: "Location",
						key: "name",
						sortable: true,
					},
					{
						label: "Money Earned",
						key: "profits",
						type: "money",
						formatter: (v) => {
							return formatter.format(v);
						},
						sortable: true,
					},
				],
				tableItems: [],
				//Game table vars
				currentPageGame: 1,
				perPageGame: 5,
				searchGame: "",
				tableFieldsGame: [
					{
						label: "Location",
						key: "name",
						sortable: true,
					},
					{
						label: "Money Earned",
						key: "profits",
						type: "money",
						formatter: (v) => {
							return formatter.format(v);
						},
						sortable: true,
					},
				],
				tableFieldsGame: [
					{
						label: "Game",
						key: "name",
						sortable: true,
					},
					{
						label: "Money In",
						key: "totalIn",
						formatter: (v) => {
							return formatter.format(v);
						},
						sortable: true,
					},
					{
						label: "Money Out",
						key: "totalOut",
						formatter: (v) => {
							return formatter.format(v);
						},
						sortable: true,
					},
					{
						label: "Money Earned",
						key: "profits",
						formatter: (v) => {
							return formatter.format(v);
						},
						sortable: true,
					},
				],
				gameTableItems: [],
			};
		},

		computed: {
			...mapGetters("gameModule", ["getGameById"]),
			...mapGetters("branchModule", ["getBranchById"]),
			...mapState("branchModule", ["branches"]),
			...mapState("gameModule", ["games"]),
		},

		methods: {
			...mapActions("transactionsModule", ["getTransactionSummary"]),
			...mapActions("branchModule", ["getBranches"]),
			...mapActions("gameModule", ["getGames"]),
			// Load widget data
			async loadWidgetData() {
				try {
					const result = await this.getTransactionSummary({});
					if (Array.isArray(result.data)) {
						if (result.data.length > 0) {
							this.widgetData.totalIn.number = formatter.format(
								result.data.reduce((a, b) => a + b.totalIn, 0)
							);
							this.widgetData.totalOut.number = formatter.format(
								result.data.reduce((a, b) => a + b.totalOut, 0)
							);
							this.widgetData.totalNetProfit.number = formatter.format(
								result.data.reduce((a, b) => a + b.profits, 0)
							);
						}
					}
				} catch (error) {
					console.log(error);
				}
			},
			// Load location
			async loadLocationTable() {
				try {
					const locationResult = await this.getTransactionSummary({
						groupBy: "branch",
					});
					if (locationResult.status == 200) {
						if (Array.isArray(locationResult.data)) {
							if (locationResult.data.length > 0) {
								const { data } = locationResult;
								this.tableItems = [];
								for (let i = 0; i < data.length; i++) {
									const branch = this.getBranchById(data[i].branch);
									this.tableItems.push({
										name: branch ? branch.name : "",
										profits: data[i].profits,
									});
								}
								this.tableItems.sort((a, b) => {
									let nameA = a.name.toUpperCase();
									let nameB = b.name.toUpperCase();
									return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
								});
							}
						}
					}
				} catch (error) {
					console.log(error);
				}
			},
			// Load data for Game Report Table
			async loadGameTable() {
				try {
					//Get data from endpoint
					const gameResult = await this.getTransactionSummary({
						groupBy: "game",
					});

					if (gameResult.status == 200) {
						if (gameResult.data.length > 0) {
							const { data } = gameResult;
							this.gameTableItems = [];
							for (let i = 0; i < data.length; i++) {
								const game = this.getGameById(data[i].game);
								if (game) {
									this.gameTableItems.push({
										name: game ? game.name : "",
										totalIn: data[i].totalIn,
										totalOut: data[i].totalOut,
										profits: data[i].profits,
									});
								}
							}
						}
					}
				} catch (error) {
					console.log(error);
				}
			},
		},
	};
</script>
